import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'

import { useMarketplaceCategories } from 'hooks'

import './Marketplace.scss'
import classNames from 'classnames'

type Props = {
  webview?: boolean
}

export default function Marketplace({ webview }: Props) {
  const categories = useMarketplaceCategories()

  return (
    <>
      <Helmet>
        <title>Jevitty Marketplace</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>

      <div
        className={classNames('row row-cols-1', {
          'row-cols-md-2 row-cols-lg-3 pt-3': !webview,
        })}
      >
        {categories.map(({ slug, title, description, icon, products }) => (
          <div className="col" key={slug}>
            <article
              className={
                webview
                  ? 'marketplace-page__category--webview'
                  : 'marketplace-page__category'
              }
            >
              <Link
                className="marketplace-page__category__link"
                to={`${webview ? '/webview' : ''}/marketplace/${slug}/`}
              >
                {icon && (
                  <img
                    className="marketplace-page__category__icon"
                    src={icon.file.url}
                    alt={title}
                  />
                )}
                <div className="marketplace-page__category__text">
                  {title && (
                    <h2 className="marketplace-page__category__title">
                      {title}
                      {products && (
                        <span className="marketplace-page__category__title__counter">
                          {' '}
                          ({products.length})
                        </span>
                      )}
                    </h2>
                  )}
                  {description && (
                    <p className="marketplace-page__category__description">
                      {description}
                    </p>
                  )}
                </div>
              </Link>
            </article>
          </div>
        ))}
      </div>
    </>
  )
}
