import React from 'react'

import AuthProvider from 'contexts/AuthProvider'
import UserProvider from 'contexts/UserProvider'
import Layout from 'components/Layout'
import Marketplace from 'components/Marketplace'

export default function MarketplacePage() {
  return (
    <AuthProvider>
      <UserProvider>
        <Layout className="marketplace-page">
          <Marketplace />
        </Layout>
      </UserProvider>
    </AuthProvider>
  )
}
